import { conf } from 'outlinejs';
import { BaseModel } from '../core/models';

export class ProductInfo extends BaseModel {
  get heightMm() {
    return this.get('heightMm');
  }

  get heightPixel() {
    return this.get('heightPixel');
  }

  get heightToleranceMm() {
    return this.get('heightToleranceMm');
  }

  get widthToleranceMm() {
    return this.get('widthToleranceMm');
  }

  get widthMm() {
    return this.get('widthMm');
  }

  get widthPixel() {
    return this.get('widthPixel');
  }

  get minPages() {
    return this.get('minPages');
  }

  get maxPages() {
    return this.get('maxPages');
  }

  get dpi() {
    return this.get('dpi');
  }

  get dpiMin() {
    return this.get('dpiMin');
  }

  get marginBottom() {
    return this.get('marginBottom');
  }

  get marginLeft() {
    return this.get('marginLeft');
  }

  get marginRight() {
    return this.get('marginRight');
  }

  get marginTop() {
    return this.get('marginTop');
  }
}

export class EventBookConfigurationFile extends BaseModel {
  get urlRoot() {
    return conf.settings.EVENT_BOOK_CONFIGURATION_FILES_URL;
  }

  get id() {
    return this.get('id') || this.get('code');
  }

  get configurationId() {
    return this.get('configurationId');
  }

  get fileType() {
    return this.get('fileType');
  }

  get fileName() {
    return this.get('fileName');
  }

  get contentType() {
    return this.get('contentType');
  }

  get contentLength() {
    return this.get('contentLength');
  }

  get isCompressed() {
    return this.get('isCompressed');
  }

  get fileUrl() {
    return this.get('fileUrl');
  }

  get uploadUrl() {
    return this.get('uploadUrl');
  }

  get uploaded() {
    return this.get('uploaded');
  }

  get width() {
    return this.get('width');
  }

  get height() {
    return this.get('height');
  }

  get dpi() {
    return this.get('dpi');
  }

  get colorProfile() {
    return this.get('colorProfile');
  }

  get fileInfo() {
    return this.get('fileInfo');
  }

  get thumbnails() {
    return this.get('thumbnails');
  }

  get pagePaperLaminationType() {
    return this.get('pagePaperLaminationType');
  }

  get legacy() {
    return this.get('legacy');
  }
}

export class EventBookConfigurationThumbnailFile extends BaseModel {
  get urlRoot() {
    return `${conf.settings.EVENT_BOOK_CONFIGURATION_FILES_URL}${this.configurationFileCode}/thumbnails/`;
  }

  get id() {
    return this.get('id');
  }

  get configurationFileCode() {
    return this.get('configurationFileCode');
  }

  set configurationFileCode(value) {
    this.set('configurationFileCode', value);
  }

  get fileName() {
    return this.get('fileName');
  }

  get contentType() {
    return this.get('contentType');
  }

  get contentLength() {
    return this.get('contentLength');
  }

  get isCompressed() {
    return this.get('isCompressed');
  }

  get fileUrl() {
    return this.get('fileUrl');
  }

  get uploadUrl() {
    return this.get('uploadUrl');
  }

  get uploaded() {
    return this.get('uploaded');
  }

  get width() {
    return this.get('width');
  }

  get height() {
    return this.get('height');
  }

  get legacy() {
    return this.get('legacy');
  }
}

export class AlbumConfigurationFile extends BaseModel {
  get urlRoot() {
    return conf.settings.ALBUM_CONFIGURATION_FILES_URL;
  }

  get id() {
    return this.get('id') || this.get('code');
  }

  get configurationId() {
    return this.get('configurationId');
  }

  get fileType() {
    return this.get('fileType');
  }

  get fileName() {
    return this.get('fileName');
  }

  get contentType() {
    return this.get('contentType');
  }

  get contentLength() {
    return this.get('contentLength');
  }

  get isCompressed() {
    return this.get('isCompressed');
  }

  get fileUrl() {
    return this.get('fileUrl');
  }

  get uploadUrl() {
    return this.get('uploadUrl');
  }

  get uploaded() {
    return this.get('uploaded');
  }

  get width() {
    return this.get('width');
  }

  get height() {
    return this.get('height');
  }

  get dpi() {
    return this.get('dpi');
  }

  get colorProfile() {
    return this.get('colorProfile');
  }

  get fileInfo() {
    return this.get('fileInfo');
  }

  get thumbnails() {
    return this.get('thumbnails');
  }

  get pagePaperLaminationType() {
    return this.get('pagePaperLaminationType');
  }

  get legacy() {
    return this.get('legacy');
  }
}

export class AlbumConfigurationThumbnailFile extends BaseModel {
  get urlRoot() {
    return `${conf.settings.ALBUM_CONFIGURATION_FILES_URL}${this.configurationFileCode}/thumbnails/`;
  }

  get id() {
    return this.get('id');
  }

  get configurationFileCode() {
    return this.get('configurationFileCode');
  }

  set configurationFileCode(value) {
    this.set('configurationFileCode', value);
  }

  get fileName() {
    return this.get('fileName');
  }

  get contentType() {
    return this.get('contentType');
  }

  get contentLength() {
    return this.get('contentLength');
  }

  get isCompressed() {
    return this.get('isCompressed');
  }

  get fileUrl() {
    return this.get('fileUrl');
  }

  get uploadUrl() {
    return this.get('uploadUrl');
  }

  get uploaded() {
    return this.get('uploaded');
  }

  get width() {
    return this.get('width');
  }

  get height() {
    return this.get('height');
  }

  get legacy() {
    return this.get('legacy');
  }
}

export class EventBoookBlockProduction extends BaseModel {
  get heightMm() {
    return this.get('heightMm');
  }

  get heightPixel() {
    return this.get('heightPixel');
  }

  get heightToleranceMm() {
    return this.get('heightToleranceMm');
  }

  get widthToleranceMm() {
    return this.get('widthToleranceMm');
  }

  get widthMm() {
    return this.get('widthMm');
  }

  get widthPixel() {
    return this.get('widthPixel');
  }

  get minPages() {
    return this.get('minPages');
  }

  get maxPages() {
    return this.get('maxPages');
  }

  get dpi() {
    return this.get('dpi');
  }

  get dpiMin() {
    return this.get('dpiMin');
  }

  get marginBottom() {
    return this.get('marginBottom');
  }

  get marginLeft() {
    return this.get('marginLeft');
  }

  get marginRight() {
    return this.get('marginRight');
  }

  get marginTop() {
    return this.get('marginTop');
  }
}

export class AlbumBlockProduction extends BaseModel {
  get heightMm() {
    return this.get('heightMm');
  }

  get heightPixel() {
    return this.get('heightPixel');
  }

  get heightToleranceMm() {
    return this.get('heightToleranceMm');
  }

  get widthToleranceMm() {
    return this.get('widthToleranceMm');
  }

  get widthMm() {
    return this.get('widthMm');
  }

  get widthPixel() {
    return this.get('widthPixel');
  }

  get minPages() {
    return this.get('minPages');
  }

  get maxPages() {
    return this.get('maxPages');
  }

  get dpi() {
    return this.get('dpi');
  }

  get dpiMin() {
    return this.get('dpiMin');
  }

  get marginBottom() {
    return this.get('marginBottom');
  }

  get marginLeft() {
    return this.get('marginLeft');
  }

  get marginRight() {
    return this.get('marginRight');
  }

  get marginTop() {
    return this.get('marginTop');
  }
}

export class AlbumMixedPaper extends BaseModel {
  get value() {
    return this.get('code');
  }

  get label() {
    return this.get('name');
  }
}

export class DefaultBlockImage extends BaseModel {
  get urlRoot() {
    return `${conf.settings.DEFAULT_BLOCK_IMAGES_URL}${this.projectId}/images/`;
  }

  get id() {
    return this.get('id');
  }

  get name() {
    return this.get('name');
  }

  get fileName() {
    return this.get('name');
  }

  get internalName() {
    return this.get('internalName');
  }

  get contentType() {
    return this.get('contentType');
  }

  get projectId() {
    return this.get('projectId');
  }

  get width() {
    return this.get('width');
  }

  get height() {
    return this.get('height');
  }

  get size() {
    return this.get('size');
  }

  get colorProfile() {
    return this.get('colorProfile');
  }

  get exifDateTimeOriginal() {
    return this.get('exifDateTimeOriginal');
  }

  get uploaded() {
    return this.get('uploaded');
  }

  get fileUrl() {
    return this.get('url');
  }

  get uploadUrl() {
    return this.get('uploadUrl');
  }

  get thumbnail() {
    return this.get('thumbnail');
  }

  get thumbnails() {
    if (this.get('thumbnail')) {
      return [
        {
          contentLength: this.get('thumbnail').size,
          contentType: this.get('thumbnail').contentType,
          fileName: this.get('thumbnail').name,
          fileUrl: this.get('thumbnail').url,
          height: this.get('thumbnail').height,
          id: this.get('thumbnail').id,
          isCompressed: this.get('thumbnail').isCompressed,
          uploadUrl: this.get('thumbnail').uploadUrl,
          uploaded: this.get('thumbnail').uploaded,
          width: this.get('thumbnail').width
        }
      ];
    }
    return [];
  }

  get legacy() {
    return this.get('legacy');
  }

  get isCompressed() {
    return this.get('isCompressed');
  }
}
