import { conf, routing } from 'outlinejs';
import { runtime } from '@outlinejs/contexts';
import { BaseAEController } from '../core/controllers';
import Logger from '../core/logger';
import { GuiErrors, GuiNotifications } from '../core/utils/index';
import { albumFunnelSteps, eventBookFunnelSteps } from '../core/utils/breadcrumb';
import { UploaderView } from './views';

import { getEventBookConfiguration } from '../eventbook/utils';
import { LayoutServicesCodes } from '../services/utils';
import {
  AlbumBlockProductionCollection,
  EventBookUserConfigurationFileCollection,
  EventBookBlockProductionCollection,
  AlbumMixedPapersCollection,
  AlbumUserConfigurationFileCollection,
  DefaultBlockImageCollection
} from './managers';
import { getAlbumConfiguration } from '../album/utils';
import {
  HEADER_AND_BREADCRUMB_COLLAPSED_HEIGHT,
  HEADER_AND_BREADCRUMB_HEIGHT
} from '../core/utils/domFunctions';
import {
  AlbumConfigurationFile,
  AlbumConfigurationThumbnailFile,
  DefaultBlockImage,
  EventBookConfigurationFile,
  EventBookConfigurationThumbnailFile
} from './models';
import EventTracker, { productInterface } from '../core/eventTracker';

const USE_STORAGE_GRID = false;

export class BaseRtpUploaderController extends BaseAEController {
  get view() {
    return UploaderView;
  }

  get funnelStep() {
    return 4;
  }

  get fileType() {
    return 'DoublePageImage';
  }

  get productType() {
    throw new Error('"productType" function must be implemented in controller');
  }

  async getProductConfiguration(configurationId) { //eslint-disable-line
    throw new Error('"getProductConfiguration" function must be implemented in controller');
  }

  getProductionCollectionInstance() {
    throw new Error('"getProductionCollectionInstance" function must be implemented in controller');
  }

  getConfigurationFileInstance(props) { //eslint-disable-line
    throw new Error('"getConfigurationFileInstance" function must be implemented in controller');
  }

  getConfigurationThumbnailFileInstance(props) { //eslint-disable-line
    throw new Error('"getConfigurationFileInstance" function must be implemented in controller');
  }

  async getMixedPapersCollection() {
    throw new Error('"getMixedPapersCollection" function must be implemented in controller');
  }

  get context() {
    return Object.assign(super.context, {
      viewHasFooter: false,
      initViewRendering: this.initViewRendering,
      user: this.user,
      customerUser: this.customerUser,
      configuration: this.configuration,
      files: this.files,
      uploaderOptions: this.uploaderOptions,
      mixedPapers: this.mixedPapers,
      onUploaderEvents: this.onUploaderEvents
    });
  }

  async initContentProps() {
    await super.initContentProps();
  }

  onCompressionComplete(compressedBlobFile, uploader) { // eslint-disable-line
    EventTracker.log(
      this.customerUser,
      'compression_success',
      Object.assign(
        {
          design_service_type: LayoutServicesCodes.readyToPrintCode
        },
        productInterface(this.configuration)
      )
    );
  }

  onCompressionError(errorName, uploader) { // eslint-disable-line
    EventTracker.log(
      this.customerUser,
      'compression_error',
      Object.assign(
        {
          design_service_type: LayoutServicesCodes.readyToPrintCode,
          error_code: errorName
        },
        productInterface(this.configuration)
      )
    );
  }

  onUploadComplete(upFile, uploader) { // eslint-disable-line
    EventTracker.log(
      this.customerUser,
      'upload_success',
      Object.assign(
        {
          design_service_type: LayoutServicesCodes.readyToPrintCode
        },
        productInterface(this.configuration)
      )
    );
  }

  onUploadError(error, uploader) { // eslint-disable-line
    const eventProps = Object.assign(
      {
        design_service_type: LayoutServicesCodes.readyToPrintCode,
        error_code: String(error)
      },
      productInterface(this.configuration)
    );

    EventTracker.log(this.customerUser, 'upload_error', eventProps);
  }

  onUploadThumbnailComplete(upThumbFile, uploader) { // eslint-disable-line
    EventTracker.log(
      this.customerUser,
      'upload_success',
      Object.assign(
        {
          design_service_type: LayoutServicesCodes.readyToPrintCode
        },
        productInterface(this.configuration)
      )
    );
  }

  onUploadThumbnailError(error, uploader) { // eslint-disable-line
    const eventProps = Object.assign(
      {
        design_service_type: LayoutServicesCodes.readyToPrintCode,
        error_code: String(error)
      },
      productInterface(this.configuration)
    );

    EventTracker.log(this.customerUser, 'upload_error', eventProps);
  }

  async init() {
    await this.initContentProps();
    this.configuration = undefined;
    this.productInfo = undefined;
    this.files = undefined;
    this.mixedPapers = undefined;
    this.uploaderOptions = undefined;
    this.onUploaderEvents = {
      'preprocess:complete': this.onCompressionComplete.bind(this),
      'preprocess:error': this.onCompressionError.bind(this),
      'upload-process:complete': this.onUploadComplete.bind(this),
      'upload-process:error': this.onUploadError.bind(this),
      'upload-thumbnail-process:complete': this.onUploadThumbnailComplete.bind(this),
      'upload-thumbnail-process:error': this.onUploadThumbnailError.bind(this)
    };

    this.startInitialRendering();

    if (runtime.isClient) {
      const configurationId = this.request.query.configurationId;
      const hasCompression = !(
        this.request.query.hasCompression && this.request.query.hasCompression === 'false'
      );

      try {
        this.configuration = await this.getProductConfiguration(configurationId);
      } catch (error) {
        let timestamp = new Date().getTime();
        Logger.error('BaseRtpUploaderController.init - Unable to load Event Book configuration', {
          error: error,
          errorCode: timestamp,
          user: this.user,
          customerUser: this.customerUser
        });
        GuiErrors.modalError(
          this.i18n.gettext('Qualcosa è andato storto. Ti preghiamo di riprovare'),
          'Error detail: loadConfigurations - code: ' + timestamp
        );
        return;
      }

      //check if configuration are with valid shop code
      if (
        this.configuration.serviceConfigurationTypeCode === LayoutServicesCodes.readyToPrintCode
      ) {
        try {
          const [files, productInfoCollection, mixedPapers] = await Promise.all([
            this.getFiles(configurationId, 'DoublePageImage'),
            this.getProductionCollectionInstance().filterByConfigurationId(configurationId),
            this.getMixedPapersCollection(this.configuration)
          ]);

          this.files = files;
          this.productInfo = productInfoCollection.first();
          this.mixedPapers = mixedPapers;

          this.uploaderOptions = {
            language: this.request.language,
            compressionEnabled: hasCompression,
            thumbnailDimension: 1800,
            debug: !conf.settings.IS_PRODUCTION_ENV,
            uploaderType: 'RTP',
            restrictions: {
              maxFileSize: 51457280,
              minNumberOfFiles: this.configuration.sheetsNumber,
              maxNumberOfFiles: this.configuration.sheetsNumber,
              dpiMin: this.productInfo.dpiMin,
              dimension: {
                widthMm: this.productInfo.widthMm,
                heightMm: this.productInfo.heightMm,
                widthToleranceMm: this.productInfo.widthToleranceMm,
                heightToleranceMm: this.productInfo.heightToleranceMm
              }
            },
            externalLinks: {
              invalidFileResolutionLink: conf.settings.DOWNLOAD_RTP_TEMPLATE_URL,
              invalidPixelSizeLink: conf.settings.DOWNLOAD_COLOR_PROFILE_URL,
              invalidByteSizeLink: conf.settings.DOWNLOAD_COLOR_PROFILE_URL
            },
            style: {
              topBarTopPositionInitial: HEADER_AND_BREADCRUMB_HEIGHT,
              topBarTopScrollBreakpoint: HEADER_AND_BREADCRUMB_HEIGHT,
              topBarTopPositionCollapsed: HEADER_AND_BREADCRUMB_COLLAPSED_HEIGHT
            }
          };
        } catch (error) {
          const timestamp = new Date().getTime();
          Logger.error('BaseRtpUploaderController.init - Unable to load RTP info', {
            error: error,
            errorCode: timestamp,
            user: this.user,
            customerUser: this.customerUser,
            configuration: this.configuration.toJSON()
          });
          GuiErrors.modalError(
            this.i18n.gettext('Qualcosa è andato storto. Ti preghiamo di riprovare'),
            'Error detail: productInfo - code: ' + timestamp
          );
          return;
        }

        this.render(this.context);

        EventTracker.log(
          this.customerUser,
          'design_service_view',
          Object.assign(productInterface(this.configuration), {
            design_service_type: this.configuration.serviceConfigurationTypeCode,
            project_id: this.request.query.professionalProjectId
          })
        );
      } else {
        Logger.error('Not implemented serviceConfigurationTypeCode', {
          serviceConfigurationTypeCode: this.configuration.serviceConfigurationTypeCode,
          customerUser: this.customerUser,
          configuration: this.configuration
        });
      }

      this.stopInitialRendering();
    }
  }

  async createConfigurationFile(uploader, upFile) {
    if (!upFile.modelId) {
      const configurationFile = this.getConfigurationFileInstance();
      await configurationFile.save({
        configurationId: this.configuration.id,
        fileType: this.fileType,
        fileName: upFile.name,
        width: upFile.width,
        height: upFile.height,
        contentType: upFile.contentType,
        colorProfile: upFile.exif.profileDescription,
        fileInfo: JSON.stringify(upFile.exif),
        dpi: upFile.exif.dpi,
        pagePaperLaminationType: upFile.paperType,
        legacy: false
      });
      upFile.modelId = configurationFile.id;
      upFile.uploadUrl = configurationFile.uploadUrl;
      // upFile.url = configurationFile.fileUrl;
      uploader.setFileState(upFile.id, upFile);

      return configurationFile;
    }
  }

  async updateConfigurationFile(uploader, upFile) {
    const configurationFile = this.getConfigurationFileInstance({ id: upFile.modelId });
    await configurationFile.save({
      uploaded: true,
      pagePaperLaminationType: upFile.paperType,
      contentLength: upFile.size,
      isCompressed: upFile.isCompressed
    });

    return configurationFile;
  }

  async createConfigurationThumbnailFile(uploader, upFile, upThumbFile) {
    if (!upThumbFile.modelId) {
      const configurationThumbFile = this.getConfigurationThumbnailFileInstance({
        configurationFileCode: upFile.modelId
      });
      await configurationThumbFile.save({
        fileName: upThumbFile.name,
        width: upThumbFile.width,
        height: upThumbFile.height,
        contentType: upThumbFile.contentType,
        legacy: false
      });

      upFile.thumbnail.modelId = configurationThumbFile.id;
      upFile.thumbnail.uploadUrl = configurationThumbFile.uploadUrl;
      // NOT WORKING image replace
      // upFile.thumbnail.url = configurationThumbFile.fileUrl;
      uploader.setFileState(upFile.id, upFile);

      return configurationThumbFile;
    }
  }

  async updateConfigurationThumbnailFile(uploader, upFile, upThumbFile) {
    const configurationThumbFile = this.getConfigurationThumbnailFileInstance({
      id: upThumbFile.modelId,
      configurationFileCode: upFile.modelId
    });
    await configurationThumbFile.save({
      uploaded: true,
      contentLength: upThumbFile.size,
      isCompressed: upThumbFile.isCompressed
    });
    return configurationThumbFile;
  }

  async removeConfigurationFile(uploader, upFile) {
    const configurationFile = this.getConfigurationFileInstance({ id: upFile.modelId });
    await configurationFile.destroy();
    return configurationFile;
  }

  closeUploader() {}

  async onChangePaperType(uploader, upFile) { // eslint-disable-line
    throw new Error('"onChangePaperType" function must be implemented in controller');
  }

  async exitFromUploader() {
    this.initViewRendering = true;
    this.render(this.context);

    const projectId = this.request.query.professionalProjectId;
    let url = conf.settings.CART_BASE_URL;
    if (projectId) {
      url = url + 'cart/' + projectId;
    }
    window.location.href = url;
  }
}

export class EventBookRTPUploaderController extends BaseRtpUploaderController {
  get productType() {
    return 'EventBook';
  }

  get funnelSteps() {
    return eventBookFunnelSteps(this.request);
  }

  getProductionCollectionInstance() {
    return new EventBookBlockProductionCollection();
  }

  getConfigurationFileInstance(props) {
    return new EventBookConfigurationFile(props);
  }

  getConfigurationThumbnailFileInstance(props) {
    return new EventBookConfigurationThumbnailFile(props);
  }

  async getProductConfiguration(configurationId) {
    return getEventBookConfiguration(configurationId);
  }

  async getMixedPapersCollection() {
    return [];
  }

  async onChangePaperType(uploader, upFile) { // eslint-disable-line
    return null;
  }

  async getFiles(configurationId, fileType) {
    let params = {
      configurationId: configurationId,
      fileType: fileType,
      pageSize: 200,
      currentPage: 1
    };
    return await new EventBookUserConfigurationFileCollection().fetch({ data: params });
  }
}

export class AlbumRTPUploaderController extends BaseRtpUploaderController {
  get productType() {
    return 'Album';
  }

  get funnelSteps() {
    return albumFunnelSteps(this.request);
  }

  getProductionCollectionInstance() {
    return new AlbumBlockProductionCollection();
  }

  getConfigurationFileInstance(props) {
    return new AlbumConfigurationFile(props);
  }

  getConfigurationThumbnailFileInstance(props) {
    return new AlbumConfigurationThumbnailFile(props);
  }

  async getProductConfiguration(configurationId) {
    return getAlbumConfiguration(configurationId);
  }

  async getMixedPapersCollection(configuration) {
    const params = {
      configurationFamily: configuration.configurationFamily,
      bindingType: configuration.bindingType,
      paperType: configuration.paperType
    };
    const mixedPapersCollection = await new AlbumMixedPapersCollection().fetch({ data: params });
    return mixedPapersCollection.toJSON();
  }

  async onChangePaperType(uploader, upFile) {
    if (upFile.modelId) {
      const configurationFile = new AlbumConfigurationFile({ id: upFile.modelId });
      await configurationFile.save({
        pagePaperLaminationType: upFile.paperType
      });
      return configurationFile;
    }
  }

  async getFiles(configurationId, fileType) {
    let params = {
      configurationId: configurationId,
      fileType: fileType,
      pageSize: 200,
      currentPage: 1
    };
    return await new AlbumUserConfigurationFileCollection().fetch({ data: params });
  }
}

class BaseFsdUploaderController extends BaseAEController {
  get view() {
    return UploaderView;
  }

  get funnelStep() {
    return 4;
  }

  get productType() {
    throw new Error('"productType" function must be implemented in controller');
  }

  async getProductConfiguration(configurationId) { //eslint-disable-line
    throw new Error('"getProductConfiguration" function must be implemented in controller');
  }

  getConfigurationFileInstance(props) { //eslint-disable-line
    return new DefaultBlockImage(props);
  }

  getConfigurationThumbnailFileInstance(props) { //eslint-disable-line
    throw new Error('"getConfigurationFileInstance" function must be implemented in controller');
  }

  async onChangePaperType(uploader, upFile) { // eslint-disable-line
    return null;
  }

  async getFiles(editorVeloceProjectId) {
    let params = {
      sort: 'name',
      pageSize: 10000,
      currentPage: 1
    };
    return await new DefaultBlockImageCollection({ editorVeloceProjectId }).fetch({ data: params });
  }

  get context() {
    return Object.assign(super.context, {
      viewHasFooter: false,
      initViewRendering: this.initViewRendering,
      user: this.user,
      customerUser: this.customerUser,
      configuration: this.configuration,
      editorVeloceProjectId: this.editorVeloceProjectId,
      files: this.files,
      uploaderOptions: this.uploaderOptions,
      onUploaderEvents: this.onUploaderEvents
    });
  }

  async initContentProps() {
    await super.initContentProps();
  }

  sendWelcomeNewUploader() {
    const title = {
      it: 'Benvenuto nella nuova esperienza di caricamento!',
      en: 'Welcome to the new upload experience!',
      es: '¡Bienvenido a la nueva experiencia de subir!'
    };

    const msg = {
      it:
        'Abbiamo migliorato la stabilità del processo per supportare la tua esperienza di acquisto. Ora potrai verificare sin da subito la correttezza dei tuoi file (profilo colore), prima che siano trasferiti ai nostri server.\n' +
        'In caso di bisogno il Customer Service è sempre a tua disposizione.',
      en:
        'We have improved process stability to support your shopping experience. Now you can immediately check the correctness of your files (color profile), before they are transferred to our servers.\n' +
        'In case of need, the Customer Service is always at your disposal.',
      es:
        'Hemos mejorado la estabilidad del proceso para respaldar su experiencia de compra. Ahora puede verificar inmediatamente la exactitud de sus archivos (perfil de color), antes de que sean transferidos a nuestros servidores.\n' +
        'En caso de necesidad, el Servicio de Atención al Cliente está siempre a su disposición.'
    };

    GuiNotifications.modalWithHideButton(
      title[this.request.language],
      msg[this.request.language],
      '__newUploaderMessage'
    );
  }

  onCompressionComplete(compressedBlobFile, uploader) { // eslint-disable-line
    EventTracker.log(
      this.customerUser,
      'compression_success',
      Object.assign(
        {
          design_service_type: LayoutServicesCodes.fullServiceDesignCode
        },
        productInterface(this.configuration)
      )
    );
  }

  onCompressionError(errorName, uploader) { // eslint-disable-line
    EventTracker.log(
      this.customerUser,
      'compression_error',
      Object.assign(
        {
          design_service_type: LayoutServicesCodes.fullServiceDesignCode,
          error_code: errorName
        },
        productInterface(this.configuration)
      )
    );
  }

  onUploadComplete(upFile, uploader) { // eslint-disable-line
    EventTracker.log(
      this.customerUser,
      'upload_success',
      Object.assign(
        {
          design_service_type: LayoutServicesCodes.fullServiceDesignCode
        },
        productInterface(this.configuration)
      )
    );
  }

  onUploadError(error, uploader) { // eslint-disable-line
    const eventProps = Object.assign(
      {
        design_service_type: LayoutServicesCodes.fullServiceDesignCode,
        error_code: String(error)
      },
      productInterface(this.configuration)
    );

    EventTracker.log(this.customerUser, 'upload_error', eventProps);
  }

  onUploadThumbnailComplete(upThumbFile, uploader) { // eslint-disable-line
    EventTracker.log(
      this.customerUser,
      'upload_success',
      Object.assign(
        {
          design_service_type: LayoutServicesCodes.fullServiceDesignCode
        },
        productInterface(this.configuration)
      )
    );
  }

  onUploadThumbnailError(error, uploader) { // eslint-disable-line
    const eventProps = Object.assign(
      {
        design_service_type: LayoutServicesCodes.fullServiceDesignCode,
        error_code: String(error)
      },
      productInterface(this.configuration)
    );

    EventTracker.log(this.customerUser, 'upload_error', eventProps);
  }

  async init() {
    await this.initContentProps();
    this.configuration = undefined;
    this.editorVeloceProjectId = undefined;
    this.files = undefined;
    this.uploaderOptions = undefined;
    this.onUploaderEvents = {
      'preprocess:complete': this.onCompressionComplete.bind(this),
      'preprocess:error': this.onCompressionError.bind(this),
      'upload-process:complete': this.onUploadComplete.bind(this),
      'upload-process:error': this.onUploadError.bind(this)
      // todo add these events when storageGrid upload is used
      // 'upload-thumbnail-process:complete': this.onUploadThumbnailComplete.bind(this),
      // 'upload-thumbnail-process:error': this.onUploadThumbnailError.bind(this)
    };

    this.startInitialRendering();

    if (runtime.isClient) {
      const configurationId = this.request.query.configurationId;
      const hasCompression = !(
        this.request.query.hasCompression && this.request.query.hasCompression === 'false'
      );

      try {
        this.configuration = await this.getProductConfiguration(configurationId);
      } catch (error) {
        let timestamp = new Date().getTime();
        Logger.error('BaseFsdUploaderController.init - Unable to load Event Book configuration', {
          error: error,
          errorCode: timestamp,
          user: this.user,
          customerUser: this.customerUser
        });
        GuiErrors.modalError(
          this.i18n.gettext('Qualcosa è andato storto. Ti preghiamo di riprovare'),
          'Error detail: BaseFsdUploaderController - code: ' + timestamp
        );
        return;
      }

      // check if full service design already requested
      if (this.configuration.fullServiceDesignRequested) {
        this.goToCart();
        return;
      }

      // check if configuration has valid service type
      if (
        this.configuration.serviceConfigurationTypeCode ===
        LayoutServicesCodes.fullServiceDesignCode
      ) {
        try {
          this.editorVeloceProjectId = this.request.query.aeVeloceProjectId;

          this.files = await this.getFiles(this.editorVeloceProjectId);

          this.uploaderOptions = {
            language: this.request.language,
            compressionEnabled: hasCompression,
            thumbnailDimension: 1800,
            debug: !conf.settings.IS_PRODUCTION_ENV,
            uploaderClient: USE_STORAGE_GRID ? 'STORAGE_GRID' : 'UP',
            uploaderType: 'FSD',
            restrictions: {},
            externalLinks: {
              invalidFileResolutionLink: conf.settings.DOWNLOAD_RTP_TEMPLATE_URL,
              invalidPixelSizeLink: conf.settings.DOWNLOAD_COLOR_PROFILE_URL,
              invalidByteSizeLink: conf.settings.DOWNLOAD_COLOR_PROFILE_URL
            },
            style: {
              topBarTopPositionInitial: HEADER_AND_BREADCRUMB_HEIGHT,
              topBarTopScrollBreakpoint: HEADER_AND_BREADCRUMB_HEIGHT,
              topBarTopPositionCollapsed: HEADER_AND_BREADCRUMB_COLLAPSED_HEIGHT
            }
          };
        } catch (error) {
          const timestamp = new Date().getTime();
          Logger.error('BaseFsdUploaderController.init - Unable to load RTP info', {
            error: error,
            errorCode: timestamp,
            user: this.user,
            customerUser: this.customerUser,
            configuration: this.configuration.toJSON()
          });
          GuiErrors.modalError(
            this.i18n.gettext('Qualcosa è andato storto. Ti preghiamo di riprovare'),
            'Error detail: productInfo - code: ' + timestamp
          );
          return;
        }

        this.sendWelcomeNewUploader();

        this.render(this.context);

        EventTracker.log(
          this.customerUser,
          'design_service_view',
          Object.assign(productInterface(this.configuration), {
            design_service_type: this.configuration.serviceConfigurationTypeCode,
            project_id: this.request.query.professionalProjectId
          })
        );
      } else {
        Logger.warning('Not implemented serviceConfigurationTypeCode', {
          serviceConfigurationTypeCode: this.configuration.serviceConfigurationTypeCode,
          customerUser: this.customerUser,
          configuration: this.configuration
        });
      }

      this.stopInitialRendering();
    }
  }

  async createConfigurationFile(uploader, upFile) {
    if (!upFile.modelId) {
      const configurationFile = this.getConfigurationFileInstance();
      await configurationFile.save({
        projectId: Number(this.editorVeloceProjectId),
        name: upFile.name,
        width: upFile.width,
        height: upFile.height,
        size: upFile.size,
        contentType: upFile.contentType,
        colorProfile: upFile.exif.profileDescription,
        exifDateTimeOriginal: upFile.exif.exifDateTimeOriginal
          ? upFile.exif.exifDateTimeOriginal
          : undefined, // todo add field to library
        legacy: !USE_STORAGE_GRID
      });
      upFile.modelId = configurationFile.id;
      upFile.uploadUrl = configurationFile.uploadUrl;
      uploader.setFileState(upFile.id, upFile);

      return configurationFile;
    }
  }

  async updateConfigurationFile(uploader, upFile) {
    const configurationFile = this.getConfigurationFileInstance({ id: upFile.modelId });
    await configurationFile.save({
      projectId: Number(this.editorVeloceProjectId),
      uploaded: true,
      isCompressed: upFile.isCompressed,
      favorite: false
    });

    return configurationFile;
  }

  async createConfigurationThumbnailFile(uploader, upFile, upThumbFile) {
    if (USE_STORAGE_GRID) {
      if (!upThumbFile.modelId) {
        const configurationThumbFile = this.getConfigurationThumbnailFileInstance({
          configurationFileCode: upFile.modelId
        });
        await configurationThumbFile.save({
          projectId: Number(this.editorVeloceProjectId),
          fileName: upThumbFile.name,
          width: upThumbFile.width,
          height: upThumbFile.height,
          contentType: upThumbFile.contentType,
          legacy: !USE_STORAGE_GRID
        });

        upFile.thumbnail.modelId = configurationThumbFile.id;
        upFile.thumbnail.uploadUrl = configurationThumbFile.uploadUrl;
        uploader.setFileState(upFile.id, upFile);

        return configurationThumbFile;
      }
    }
  }

  async updateConfigurationThumbnailFile(uploader, upFile, upThumbFile) {
    if (USE_STORAGE_GRID) {
      const configurationThumbFile = this.getConfigurationThumbnailFileInstance({
        id: upThumbFile.modelId,
        configurationFileCode: upFile.modelId
      });
      await configurationThumbFile.save({
        projectId: Number(this.editorVeloceProjectId),
        uploaded: true,
        contentLength: upThumbFile.size,
        isCompressed: upThumbFile.isCompressed
      });
      return configurationThumbFile;
    }
  }

  async removeConfigurationFile(uploader, upFile) {
    const configurationFile = this.getConfigurationFileInstance({
      id: upFile.modelId,
      projectId: Number(this.editorVeloceProjectId)
    });
    await configurationFile.destroy();
    return configurationFile;
  }

  closeUploader() {}

  async exitFromUploader() {
    GuiNotifications.modalConfirmAction(
      this.i18n.gettext(
        'Premendo sul pulsante "Conferma" verrà avviato il processo di impaginazione. Vuoi confermare?'
      ),
      this.i18n.gettext('Attenzione'),
      this.i18n.gettext('conferma'),
      this.i18n.gettext('Modifica'),
      this.confirmFsdProject.bind(this)
    );
  }

  async confirmFsdProject() {
    this.startInitialRendering();

    try {
      this.configuration.fullServiceDesignRequested = true;
      await this.configuration.save();

      EventTracker.log(
        this.customerUser,
        'design_service_requested_success',
        Object.assign(productInterface(this.configuration), {
          design_service_type: this.configuration.serviceConfigurationTypeCode
        })
      );
    } catch (err) {
      const timestamp = new Date().getTime();
      Logger.error(
        'BaseFullServiceDesignPreviewController.confirmFsdProject - Unable to update product configuration',
        {
          error: err,
          errorCode: timestamp,
          customerUser: this.customerUser
        }
      );
      this.stopInitialRendering();

      EventTracker.log(
        this.customerUser,
        'design_service_requested_error',
        Object.assign(productInterface(this.configuration), {
          design_service_type: this.configuration.serviceConfigurationTypeCode
        })
      );

      return;
    }

    const projectId = this.request.query.professionalProjectId;
    let url = 'thankyou:main';
    if (projectId) {
      let thankYouUrl = routing.Utils.reverse('thankyou:main');
      url = `${thankYouUrl}?projectId=${projectId}`;
    }

    this.stopInitialRendering();
    this.response.navigate(url);
  }

  goToCart() {
    this.initViewRendering = true;
    this.render(this.context);

    const projectId = this.request.query.professionalProjectId;
    let url = conf.settings.CART_BASE_URL;
    if (projectId) {
      url = url + 'cart/' + projectId;
    }
    window.location.href = url;
  }
}

export class EventBookFsdUploaderController extends BaseFsdUploaderController {
  get productType() {
    return 'EventBook';
  }

  get funnelSteps() {
    return eventBookFunnelSteps(this.request);
  }

  async getProductConfiguration(configurationId) {
    return getEventBookConfiguration(configurationId);
  }
}

export class AlbumFsdUploaderController extends BaseFsdUploaderController {
  get productType() {
    return 'Album';
  }

  get funnelSteps() {
    return albumFunnelSteps(this.request);
  }

  async getProductConfiguration(configurationId) {
    return getAlbumConfiguration(configurationId);
  }
}
