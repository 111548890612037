import { routers } from 'outlinejs';
import { routing } from 'outlinejs';

import {
  AlbumFsdUploaderController,
  AlbumRTPUploaderController,
  EventBookFsdUploaderController,
  EventBookRTPUploaderController
} from './controllers';

export default class extends routers.BaseRouter {
  static get urlPatterns() {
    return {
      'event-book-readytoprint': routing.url(
        'uploader-rtp:EventBook',
        EventBookRTPUploaderController
      ),
      'album-readytoprint': routing.url('uploader-rtp:Album', AlbumRTPUploaderController),
      'event-book-fullservicedesign': routing.url(
        'uploader-fsd:EventBook',
        EventBookFsdUploaderController
      ),
      'album-fullservicedesign': routing.url('uploader-fsd:Album', AlbumFsdUploaderController)
    };
  }
}
