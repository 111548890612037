import React from 'react';
import { views } from 'outlinejs';
import { AeLoader } from '@photosi/albumepoca-ui';
import AeUploader from '@photosi/react-uploader';

import './styles/main.scss';

export class UploaderView extends views.BaseView {
  render() {
    const { initViewRendering, files, uploaderOptions } = this.props;

    return (
      <div>
        <AeLoader active={initViewRendering} />
        {!initViewRendering && files !== undefined ? (
          <AeUploader
            initialFiles={files}
            onBeforeFileUpload={this.delegate.createConfigurationFile.bind(this.delegate)}
            onAfterFileUpload={this.delegate.updateConfigurationFile.bind(this.delegate)}
            onBeforeThumbnailUpload={this.delegate.createConfigurationThumbnailFile.bind(
              this.delegate
            )}
            onAfterThumbnailUpload={this.delegate.updateConfigurationThumbnailFile.bind(
              this.delegate
            )}
            onRemoveFile={this.delegate.removeConfigurationFile.bind(this.delegate)}
            onCloseUploader={this.delegate.exitFromUploader.bind(this.delegate)}
            mixedPaperOptions={this.props.mixedPapers}
            onChangePaperType={this.delegate.onChangePaperType.bind(this.delegate)}
            opts={uploaderOptions}
            onUploaderEvents={this.props.onUploaderEvents}
          />
        ) : null}
      </div>
    );
  }
}
