import { BaseCollection } from '../core/managers';
import { conf } from 'outlinejs';

import {
  AlbumBlockProduction,
  AlbumConfigurationFile,
  AlbumMixedPaper,
  DefaultBlockImage,
  EventBookConfigurationFile,
  EventBoookBlockProduction
} from './models';

class BlockProductionCollection extends BaseCollection {
  async filterByConfigurationId(configurationId) {
    return this.fetch({ data: { configurationId: configurationId } });
  }
}

export class EventBookBlockProductionCollection extends BlockProductionCollection {
  get url() {
    return conf.settings.EVENT_BOOK_BLOCK_PRODUCTION_INFO_URL;
  }

  get model() {
    return EventBoookBlockProduction;
  }
}

export class AlbumBlockProductionCollection extends BlockProductionCollection {
  get url() {
    return conf.settings.ALBUM_BLOCK_PRODUCTION_INFO_URL;
  }

  get model() {
    return AlbumBlockProduction;
  }
}

export class EventBookUserConfigurationFileCollection extends BaseCollection {
  get url() {
    return conf.settings.EVENT_BOOK_CONFIGURATION_FILES_URL;
  }

  get name() {
    return 'EventBookUserConfigurationFileCollection';
  }

  get model() {
    return EventBookConfigurationFile;
  }
}

export class AlbumMixedPapersCollection extends BaseCollection {
  get url() {
    return conf.settings.ALBUM_MIXED_PAPERS_URL;
  }

  get model() {
    return AlbumMixedPaper;
  }
}

export class AlbumUserConfigurationFileCollection extends BaseCollection {
  get url() {
    return conf.settings.ALBUM_CONFIGURATION_FILES_URL;
  }

  get name() {
    return 'AlbumUserConfigurationFileCollection';
  }

  get model() {
    return AlbumConfigurationFile;
  }
}

export class DefaultBlockImageCollection extends BaseCollection {
  constructor(options) {
    super();
    this.editorVeloceProjectId = options.editorVeloceProjectId;
  }

  get url() {
    return `${conf.settings.DEFAULT_BLOCK_IMAGES_URL}${this.editorVeloceProjectId}/images/`;
  }

  get name() {
    return 'DefaultBlockImageCollection';
  }

  get model() {
    return DefaultBlockImage;
  }
}
